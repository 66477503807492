<template>
<div class="home">
    <div class="messDetail">
        <HeadTop />
        <span style="margin-left:10px">></span>
        <span style="margin-left:10px">{{title}}</span>
    </div>
    <div class="content">
        <div class="contLeft">
            <div class="bookMess">
                <div class="bookImg">
                    <img class="bookImg" :src="resBasePath+'/'+mess.coverPath" alt="">
                </div>
                <div class="bookDetail">
                    <div class="bookTitle">
                        {{mess.title}}
                    </div>
                    <a-row class="bookContent">
                        <a-col :span="6" class="mc">
                            <div>出&nbsp; 版 社</div>
                            <div>出版时间</div>
                            <div>作者</div>
                            <div>ISBN</div>
                        </a-col>
                        <a-col :span="18" class="name">
                            <div>{{mess.press}}</div>
                            <div>{{format.format(mess.createDate)}}</div>
                            <div>{{mess.author}}</div>
                            <div>{{mess.number}}</div>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <div class="user">
                <div>推荐理由</div>
                <p>{{mess.recommendReason}}</p>
            </div>
            <div class="user">
                <div>作者简介</div> 
                <p>{{mess.authorIntro || '暂无信息'}}</p>
            </div>
            <div class="user">
                <div>目录</div>
                <p>{{mess.list || '暂无信息'}}</p>
            </div>
        </div>
        <div class="contRight">
            <div class="title">
                <img width="20px" height="20px" src="../../assets/img/54.png" alt="">
                <span class="good_product">相关推荐</span>
            </div>
            <!-- <div v-if="mess.recommendList.length<=0"> <a-empty></a-empty></div> -->
            <ul class="bookRecomend">
                <li @click="recomFun(item)" v-for="(item,index) in mess.recommendList" :key="index">
                    <img :src="resBasePath+'/'+item.coverPath" alt="">
                    <div>{{item.title}}</div>
                </li>
            </ul>
        </div>
    </div>
    <div class="replay">
        <div class="answerBgcs">
            <a-textarea v-model="replayValue" :auto-size="{ minRows: 6, maxRows: 10 }" />
            <div style="text-align:right;margin-top:20px;">
                <a-button type="danger" @click="savePingLun">发布</a-button>
            </div>
        </div>
        <div v-if="pingLunList.length>0" class="list">
            <div class="num">
                <span class="text">全部评论</span>
                <span class="num_num">{{pingLunTotal}}</span>
            </div>
            <ul class="listUl">
                <li class="listLi" v-for="(item,index) in pingLunList" :key="index">
                    <div class="imgUser">
                        <img v-if="item.userPhoto&&item.userPhoto!=null" class="userImg" :src="resBasePath+'/'+item.userPhoto" alt="" />
                        <img v-else class="userImg" src="../../assets/img/57.png" alt="">
                    </div>
                    <div class="userDetail">
                        <div class="title">{{item.userName}}</div>
                        <div class="content">{{item.content}}</div>
                        <div class="date">{{format.format(item.createDate)}}</div>
                    </div>
                </li>
            </ul>
            <div v-if="pingLunList.length>5" class="lookMore" @click="getMoreNum">查看更多</div>
        </div>
    </div>
</div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import format from '@/utils/format.js'
import axios from 'axios'
export default {
  name: 'bookRecommend',
  data (){
      return {
        format,
        mess: {},
        pageNum: 1,
        pageSize: 5,
        replayValue: '',
        resBasePath: this.$resBasePath,
        id: '1',
        businessType: 1,
        title:'',
        pingLunList: [], // 评论列表
        pingLunTotal: 0 // 评论数量
      }
  },
  mounted (){
      if(this.$route.params!=''){
          const mess = JSON.parse(sessionStorage.getItem('bookDetail'))
          this.id = mess.id
          this.title = mess.title
      }else{
          this.id = '1'
      }
      this.getDetail()
      this.getCommentPage()
  },
  methods:{
        getDetail(){
            let _this =this
            const param = {
                id: _this.id
            }
            axios({
                url: _this.$baseUrl + 'official/bookStore/getBookDetail',
                method: 'POST',
                data: param
                }).then(res => {
                    if(res && res.data &&res.data.resultCode===0){   
                        _this.mess = res.data.resultData
                        _this.title = _this.mess.title
                    }
            })
        },
        recomFun(item){ //相关推荐
            this.id = item.id
            this.getDetail()
        },
    //official/comment/getCommentPage
      getMoreNum(){
          this.pageNum++
          this.getCommentPage()
      },
      getCommentPage(){ // 获取文章评论
        const param = {
            rootId: this.id,
            businessType: '1',
            pageNum: this.pageNum,
            pageSize: this.pageSize
        }
        let _this = this
        axios({
            url: _this.$baseUrl + 'official/comment/getCommentPage',
            method: 'POST',
            data: param
        }).then(res => {
            if(res&&res.data&&res.data.resultCode===0){
                if(res.data.resultData.list.length===0){
                    _this.$message.warning('没有更多数据了～')
                    return 
                }
                _this.pingLunTotal = res.data.resultData.total
                if(_this.pageNum === 1){
                    _this.pingLunList = res.data.resultData.list
                }else{
                    _this.pingLunList = [..._this.pingLunList,...res.data.resultData.list]
                }
                
            }
        })
      },
      savePingLun(){ //保存评论
        const param = {
            userId: '436c0ca7efc145a8ba15bb1e6fecb520',
            businessType: '1',
            content: this.replayValue,
            rootId: this.id
        }
        let _this = this
        axios({
            url: this.$baseUrl + 'official/comment/save',
            method: 'POST',
            data: param
        }).then(res => {
            if(res && res.data && res.data.resultCode === 0){
                _this.$message.success('发布评论成功')
                _this.getCommentPage()
            }
        })
      },
      lookDetail(item){
          this.id = item.id
          this.getDetail()
      }
    },
  components: {
    HeadTop
  },
}
</script>

<style scoped lang="less">
    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .home{
        background: #f6f6f6;
        width: 1140px;
        min-height: 500px;
        margin: auto;
        .messDetail{
            display: flex;
            line-height:60px;
        }
        .content{
            width: 1140px;
            margin: auto;
            display: flex;
            .contLeft{
                width: 870px;
                // min-height: 400px;
                background: #fff;
                padding: 30px;
                .bookMess{
                    display: flex;
                    margin-bottom: 30px;
                    .bookImg{
                        width: 190px;
                        height: 200px;
                    }
                    .bookDetail{
                        margin-left: 20px;
                        .bookTitle{
                            margin-top: 10px;
                            height: 30px;
                            font-size: 22px;
                            font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
                            font-weight: bold;
                            color: #000000;
                            line-height: 30px
                        }
                        .bookContent{
                            margin-top: 20px;
                            margin-left: 10px;
                            width: 100%;
                            .mc{
                                width: 60px;
                                height: 30px;
                                font-size: 14px;
                                line-height: 30px;
                                text-align: left;
                                color: #7f7f7f;
                            }
                            .name{
                                margin-left: 10px;
                                height: 30px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 30px;
                                text-align: left;
                                color: #000000;
                            }
                        }
                    }
                }
                .user{
                    font-weight: 500;
                    color: #000000;
                    line-height: 30px; 
                    font-size: 18px;
                    p{
                        font-size: 14px;
                        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                        font-weight: 400;
                        color: #000000;
                        line-height: 26px;
                    }
                }
            }
            .contRight{
                margin-left:20px ;
                width: 250px;
                min-height: 400px;
                background: #fff;
                padding: 25px 20px;
                .title{
                    height: 40px;
                    line-height: 40px;
                    .good_product{
                        font-size: 16px;
                        color: #DB5647;
                        font-weight: 500;
                        margin-left: 10px;
                    }
                }
                .bookRecomend{
                    li{
                        width: 100%;
                        text-align: center;
                        img{
                            width: 144px;
                            height: 156px;
                        }
                        div{
                            font-size: 16px;
                            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                            font-weight: 500;
                            color: #000000;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
        //评论
        .replay{
            margin-top: 20px;
            width: 870px;
            min-height: 300px;
            background: #fff;
            margin-bottom: 30px;
            padding: 30px;
            .answerBgcs{
                height: 240px;
                border-bottom: 1px solid #eee9de;
            }
            .list{
                margin-top: 15px;
                .num{
                    height: 28px;
                    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                    line-height: 28px;
                    .text{
                        font-size: 16px;
                        color: #000000;
                        font-weight: 500;
                    }
                    .num_num{
                        color: #7F7F7F;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }
                .listUl{
                    .listLi{
                        display: flex;
                        border-bottom: 1px solid #eee9de;
                        width: 100%;
                        padding: 10px 0px;
                        .imgUser{
                            width: 50px;
                            height: 100px;
                            .userImg{
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                            }
                        }
                        .userDetail{
                            margin-left: 5px;
                            width: 100%;
                            margin-left: 10px;
                            .name{
                                height: 28px;
                                line-height: 28px;
                                color: #000;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .content{
                                margin-top: 8px;
                                line-height: 20px;
                                font-size: 14px;
                                width:100%;
                            }
                            .date{
                                margin-top: 8px;
                                color: #7F7F7F;
                                font-size: 12px;
                            }
                        }
                    }
                }
                .lookMore{
                    margin-top: 10px;
                    width: 100%;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    color: #C59A61;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }
    .answerBgcs .ant-input {
        background-color: #f6f6f6;
        padding: 20px;
        box-sizing: border-box;
    }
    .ant-btn-danger{
        background: #DB5647;
    }
</style>